import React from 'react'

const ThankYou = () => {
    return (
        <div className="container-fluid">
            <div className="container">
                <div className="row mt-5 pt-5">
                    <img src="https://media.licdn.com/dms/image/C4E12AQGdxKcS-O5k5A/article-cover_image-shrink_720_1280/0/1606113469235?e=2147483647&v=beta&t=6QNuUvVX2oiCfIwW9MWpjsBIqMSqVUr3c-qfHSSrC9M" width="100%" height="100%" alt="" />
                </div>
            </div>
        </div>
    )
}

export default ThankYou